<template>
  <v-card class="join-team-dialog">
    <div class="join-team-dialog__header">
      <h4>
        {{ $t("join_team") }}
      </h4>
      <v-icon class="join-team-dialog__close" @click="$emit('onClose')">
        close
      </v-icon>
      <v-text-field
        v-model="search"
        class="join-team-dialog__search"
        filled
        rounded
        dense
        clearable
        prepend-inner-icon="mdi-magnify"
        :placeholder="$t('search')"
        @input="onSearch"
        @click:clear="search = ''"
      />
    </div>
    <div class="join-team-dialog__content">
      <h3 class="join-team-dialog__title" v-if="search.length < 3">
        {{ $t("my_teams") }}
      </h3>
      <span class="join-team-dialog__text" v-if="search.length < 3">
        {{ $t("tickets_join_team_description") }}
      </span>
      <div class="teams-list">
        <team-card
          v-for="(item, index) in teams"
          :key="index"
          :item="item"
          disable-route
          :avatar-size="50"
          @click="onSelectTeam"
        />
      </div>
      <div class="card-create" @click="$emit('onCreateTeam')">
        <img
          alt="logo"
          src="@/assets/create-icon.svg"
          class="card-create__logo"
        />
        <span class="card-create__text">
          {{ $t("create_new_team") }}
        </span>
      </div>
    </div>
  </v-card>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TeamCard from "@/components/TeamCard";
import { debounce } from "lodash";

export default {
  name: "JoinTeamDialog",
  components: { TeamCard },
  props: {
    tickets: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      search: "",
    };
  },
  computed: {
    ...mapGetters(["myTeams", "teamsList"]),
    teams() {
      let res = [];
      if (this.search.length >= 3) {
        res = [
          ...this.myTeams,
          ...this.teamsList.filter((item) => item.isPublic && !item.joined),
        ];
      } else {
        res = [...this.myTeams];
      }
      return res.filter((item) =>
        item.name.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    ...mapActions([
      "loadTeamList",
      "loadMyTeams",
      "assignTeamToTickets",
      "handleToolbarNotification",
    ]),
    async onSelectTeam(team) {
      try {
        this.$emit("onClose");
        await this.assignTeamToTickets({
          ticket_ids: this.tickets,
          team_id: team.id,
        });
        this.handleToolbarNotification({
          type: "success",
          text: this.$t("notification_join_team_on_event", {
            team_name: team.name,
          }),
          isVisible: true,
          closeTimeout: 3000,
        });
      } catch (error) {
        console.log(error);
        this.handleToolbarNotification({
          type: "warning",
          text: this.$t("server_error"),
          isVisible: true,
          closeTimeout: 3000,
        });
      }
    },
    onSearch: debounce(function () {
      if (this.search.length >= 3)
        this.loadTeamList({
          page: 1,
          perPage: 100,
          search: this.search,
        });
    }, 200),
  },
  async mounted() {
    await this.loadMyTeams();
  },
};
</script>

<style lang="scss" scoped>
.join-team-dialog {
  padding: 24px 0;
  position: relative;
  height: 613px;

  .join-team-dialog__header {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-left: 24px;
    margin-right: 24px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    .join-team-dialog__close {
      color: black;
      position: absolute;
      cursor: pointer;
      top: 25px;
      right: 24px;
      width: 15px;
      height: 15px;

      &:hover,
      &:focus {
        &:after {
          opacity: 0;
        }
      }
    }
    .join-team-dialog__search {
      padding-top: 21px;
      font-size: 14px;

      &::v-deep {
        .v-input__slot {
          min-height: 44px;
          padding: 0 10px;

          .v-input__prepend-inner {
            .v-input__icon--prepend-inner {
              padding-right: 0;

              .v-icon {
                color: rgba(0, 0, 0, 0.54) !important;
                font-size: 20px;
                margin-top: 2px;
              }
            }
          }

          .v-input__append-inner {
            .v-input__icon {
              padding-right: 0;

              .v-icon {
                font-size: 20px;
                margin-top: 2px;
                color: rgba(0, 0, 0, 0.54) !important;
              }
            }
          }

          & + .v-text-field__details {
            margin-bottom: 12px;
          }
        }
      }
    }
  }
  .join-team-dialog__content {
    padding-top: 16px;
    padding-left: 24px;
    padding-right: 24px;
    max-height: calc(612px - 144px);
    overflow-y: auto;
    .join-team-dialog__title {
      padding-bottom: 4px;
    }
    .join-team-dialog__text {
      display: inline-block;
      font-size: 14px;
      line-height: 18px;
      max-width: 85%;
    }
    .teams-list {
      margin-top: 24px;
    }
    .card-create {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      background: #eaeaea;
      height: 100px;
      margin-top: 9px;
      margin-bottom: 24px;
      cursor: pointer;
      .card-create__logo {
        width: 20px;
        height: 20px;
      }
      .card-create__text {
        padding-left: 11px;
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
        padding-top: 2px;
      }
    }
  }

  @media screen and (max-width: 499px) {
    position: fixed;
    bottom: 0;
    right: 0;

    .join-team-dialog__header {
      margin-left: 15px;
      margin-right: 15px;
      margin-bottom: 5px;

      .join-team-dialog__search {
        &::v-deep {
          .v-input__slot {
            & + .v-text-field__details {
              margin-bottom: 29px;
            }
          }
        }
      }
    }

    .join-team-dialog__content {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}
::v-deep .team-card {
  height: 100px;
  border-radius: 4px;

  .team-card-content {
    .team-card-avatar {
      padding-left: 21px !important;
    }

    .team-card-common-info {
      color: white;
      padding: 0 16px !important;
    }
  }
}
</style>
